export const wanderData = {
  key: 'wander',
  title: 'ISC数字小镇',
  h5Img: 'https://p5.ssl.qhimg.com/t0137b292795149b4bc.png',
  aImg: 'https://p0.ssl.qhimg.com/t016a6d464847941511.png',
  desc: '欢迎来到ISC数字小镇NEW50！小镇依托 360 智脑打造而成，搭建了数实融合、立体多维的线上场景。在这片土地上，知名企业家、学者、技术专家都是以“数字人”形象出现的，他们围绕热点技术话题展开50余场精彩演讲，您可以随时与小镇上的数字嘉宾进行对话聊天和专业咨询，在这里尽情体验“Al 大模型 + 数字安全 " 的更多可能吧!',
  h5btn: 'https://p5.ssl.qhimg.com/t01ae5ae7af8ae6aafe.png',
  btn: 'https://p1.ssl.qhimg.com/t0154c831723dfb8c4f.png',
  moreUrl: 'https://isc.n.cn'
}
export const pageData = {
  video: 'https://isc.360kan.com/vod-isc-vod-101240-beijing/TL2a21c9a94cb90005dc2df19883b7af29.mp4',
  poster: 'https://p4.ssl.qhimg.com/t018617dd31a78cdab8.png'
}
export const coreArr = [
  {
    id: 1,
    key: 'marketing',
    title: '会展营销',
    poster: 'https://p3.ssl.qhimg.com/t015ec4b9b0b2dea4e0.png',
    video:
      'https://isc.360kan.com/vod-isc-vod-101240-beijing/TL730b7df625e5454f3cb3ccd3878c4fdc.mp4',
    aImg: '',
    desc: '在大型会展领域拥有丰富的管理经验、专业的运营团队和成熟的资源体系，专注为数字安全行业及品牌提供全流程会展营销服务。至今已承接观潮网络空间论坛、世界智能大会、数字中国建设成果展览会、智博会、数博会、上海数字创新大会、天府国际网络安全高峰论坛、黑客世界大师赛等国家级大会、行业会议、赛事活动的线上线下会展营销项目。 "观潮”是中国首个多方网络空间智库品牌。由360公司、中国国际战略学会、中国创新与发展战略研究会发起，并在2016年互联网安全大会(ISC2016)期间举办了首次会议。使命是促进网络空间创新、安全、交流、合作。',
    tag: ['ISC观潮会展营销服务', '大型会议、行业会议、赛事活动营销策划']
  },
  {
    id: 2,
    key: 'consult',
    title: '战略咨询',
    poster: 'https://p4.ssl.qhimg.com/t014f2055a24aa65e9b.png',
    video:
      'https://isc.360kan.com/vod-isc-vod-101240-beijing/TLa1d47605567238a548ca9a5839e0477d.mp4',
    aImg: `https://p1.ssl.qhimg.com/t01fb568d31c37b34c4.png`,
    desc: '致力于以丰富的数据洞察、深入的产业研究助力全球客户实现市场目标，面向技术市场和垂直行业提供战略咨询、行业调研等服务。拥有Gartner、IDC、赛迪顾问等50多家国内外权威合作机构以及2000余专家智库提供专业评审，能够为安全管理者提供决策建议、为安全建设者提供行动指南、为行业数字化提供实践参考，至今已发布近40篇市场分析报告。2023年ISC启动技术报告系列调研，将发布首个数字安全行业行动部署指南。',
    tag: ['安全市场分析调研', '安全部署指南'],
    moreUrl: 'https://isc.360.com/new/stratage.html'
  },
  {
    id: 3,
    key: 'safe',
    title: 'ISC.AI学苑',
    poster: '',
    video:
      'https://isc.360kan.com/vod-isc-vod-101240-beijing/TL1ca6920c3205a86e463d1b493af3aaba.mp4',
    aImg: '',
    desc: '以公益普惠性服务教育为导向，联合高职院校、研究机构、教育培训等机构建立校企联盟，为企业和个人提供结合实训靶场平台的SaaS化安全专业课程、网络安全大赛、岗位技能培训和就业指导等全链条服务,以行业生态力量打造多元化安全人才培训体系，探索科学可行的网络安全人才培养模式,致力于解决国家网安人才需求缺口和短板，为网络强国建设提供高素质人才队伍支撑。',
    tag: ['SaaS化服务模式', '黑客大社区'],
    moreUrl: 'https://study.360.net/homepage/home'
  },
  {
    id: 4,
    key: 'media',
    title: '媒体公关',
    poster: 'https://p3.ssl.qhimg.com/t0105a42e229d7afec4.png',
    video:
      'https://isc.360kan.com/vod-isc-vod-101240-beijing/TL4931dc05ef34d961946d0e12fb86d03b.mp4',
    aImg: '',
    desc: '整合公关、营销和数字化能力，结合数字安全行业差异化传播需求及传播特征，建立品牌传播服务体系，提供品牌咨询、创意策划、项目执行、媒体投放等全流程公关服务。下设ISC、安全客两大品牌，孵化《ISC年终讲》、《ISC直击》、《ISC Talk》、《看见安全“客”》、《百家讲堂》、《安全头条》等IP。媒体资源覆盖党央媒、科技媒体、财经媒体、行业头部媒体等，实现单次传播亿级曝光。',
    tag: ['全流程公关服务', '实现单次传播亿级曝光'],
    outSide: [
      // 外链
      {
        logo: 'https://s0.ssl.qhres2.com/static/418e9fd4fef01e5e.svg',
        text: 'ISC视频平台',
        router: 'https://isc.360.com/video/'
      },
      {
        logo: 'https://s2.ssl.qhres2.com/static/6b60d0e402562e9a.svg',
        text: '安全KER',
        router: 'https://www.anquanke.com/'
      }
    ]
  },
  {
    id: 5,
    key: 'create',
    title: '创投孵化',
    poster: 'https://p2.ssl.qhimg.com/t0120c5d0257fa668ae.png',
    video:
      'https://isc.360kan.com/vod-isc-vod-101240-beijing/TLa03bf744da6f513eaf09abf94f5e7cc6.mp4',
    aImg: '',
    desc: '依托丰富的信息和资源背景，打造《创新沙盒独角兽大赛》、《未来数字安全名人堂》、《数字安全创新能力百强》等IP，深耕数字安全行业，发掘优质创新企业，拉通专业投融资机构，深度参与产业融合，共建良性、可持续发展的生态圈。目前已助推20余家企业完成数十亿元融资。',
    tag: ['孵化创新企业', '完成数十亿元融资']
  },
  {
    id: 6,
    key: 'ecology',
    title: '生态联盟',
    poster: 'https://p2.ssl.qhimg.com/t0193c4dc863339adcc.png',
    video:
      'https://isc.360kan.com/vod-isc-vod-101240-beijing/TL41f6d721b1a5e9493b61683349714111.mp4',
    aImg: '',
    desc: 'ISC数字安全生态联盟发起“城市宏图生态计划”，通过为城市打造城市主题IP峰会、挖掘精尖特新企业、引入行业生态、培育孵化初创企业，推动亿级城市数字安全产业集群落地。目前ISC数字安全生态联盟已经发展了500多家生态厂商资源，200多家会员单位，通过安全能力的资源整合，抢抓城市产业集群发展机遇，携手共建城市数字安全产业生态，为数字中国建设增筋强骨。',
    tag: ['500多家生态厂商资源', '共建城市数字安全产业生态'],
    moreUrl: 'https://isc.360.com/new/league.html'
  }
]
export const iscData = {
  bgc: 'https://p4.ssl.qhimg.com/t0132a7098590514eba.gif',
  poster: ''
}
