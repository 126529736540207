<script setup>
import { ref } from 'vue'
const currentNum = ref(0)
const coreArr = [
  {
    id: 0,
    key: 'marketing',
    headerImg: 'https://p1.ssl.qhimg.com/t01ac458353e1aaa026.png',
    title: '会展营销',
    coreLogo: 'https://p4.ssl.qhimg.com/t012f8bda47293d22f7.png',
    desc: '在大型会展领域拥有丰富的管理经验、专业的运营团队和成熟的资源体系，专注为数字安全行业及品牌提供全流程会展营销服务。至今已承接观潮网络空间论坛、世界智能大会、数字中国建设成果展览会、智博会、数博会、上海数字创新大会、天府国际网络安全高峰论坛、黑客世界大师赛等国家级大会、行业会议、赛事活动的线上线下会展营销项目。 "观潮”是中国首个多方网络空间智库品牌。由360公司、中国国际战略学会、中国创新与发展战略研究会发起，并在2016年互联网安全大会(ISC2016)期间举办了首次会议。使命是促进网络空间创新、安全、交流、合作。',
    tag: ['ISC观潮会展营销服务', '大型会议、行业会议、赛事活动营销策划']
  },
  {
    id: 1,
    key: 'consult',
    headerImg: 'https://p0.ssl.qhimg.com/t012a2e5f87b5b5e2bf.png',
    title: '战略咨询',
    coreLogo: 'https://p4.ssl.qhimg.com/t012f8bda47293d22f7.png',
    desc: '致力于以丰富的数据洞察、深入的产业研究助力全球客户实现市场目标，面向技术市场和垂直行业提供战略咨询、行业调研等服务。拥有Gartner、IDC、赛迪顾问等50多家国内外权威合作机构以及2000余专家智库提供专业评审，能够为安全管理者提供决策建议、为安全建设者提供行动指南、为行业数字化提供实践参考，至今已发布近40篇市场分析报告。2023年ISC启动技术报告系列调研，将发布首个数字安全行业行动部署指南。',
    tag: ['安全市场分析调研', '安全部署指南']
  },
  {
    id: 2,
    key: 'safe',
    headerImg: 'https://p1.ssl.qhimg.com/t01ab508ff132b01398.png',
    title: 'ISC.AI学苑',
    coreLogo: 'https://p4.ssl.qhimg.com/t012f8bda47293d22f7.png',
    desc: '以公益普惠性服务教育为导向，联合高职院校、研究机构、教育培训等机构建立校企联盟，为企业和个人提供结合实训靶场平台的SaaS化安全专业课程、网络安全大赛、岗位技能培训和就业指导等全链条服务,以行业生态力量打造多元化安全人才培训体系，探索科学可行的网络安全人才培养模式,致力于解决国家网安人才需求缺口和短板，为网络强国建设提供高素质人才队伍支撑。',
    tag: ['SaaS化服务模式', '黑客大社区']
  },
  {
    id: 3,
    key: 'media',
    headerImg: 'https://p1.ssl.qhimg.com/t01ab508ff132b01398.png',
    title: '媒体公关',
    coreLogo: 'https://p4.ssl.qhimg.com/t012f8bda47293d22f7.png',
    desc: '整合公关、营销和数字化能力，结合数字安全行业差异化传播需求及传播特征，建立品牌传播服务体系，提供品牌咨询、创意策划、项目执行、媒体投放等全流程公关服务。下设ISC、安全客两大品牌，孵化《ISC年终讲》、《ISC直击》、《ISC Talk》、《看见安全“客”》、《百家讲堂》、《安全头条》等IP。媒体资源覆盖党央媒、科技媒体、财经媒体、行业头部媒体等，实现单次传播亿级曝光。',
    tag: ['全流程公关服务', '实现单次传播亿级曝光'],
    outSide: [
      // 外链
      {
        logo: 'https://s0.ssl.qhres2.com/static/418e9fd4fef01e5e.svg',
        text: 'ISC视频平台',
        router: 'https://isc.360.com/video/'
      },
      {
        logo: 'https://s2.ssl.qhres2.com/static/6b60d0e402562e9a.svg',
        text: '安全客媒体平台',
        router: 'https://www.anquanke.com/'
      }
    ]
  },
  {
    id: 4,
    key: 'create',
    headerImg: 'https://p2.ssl.qhimg.com/t017ee2114ff617991c.png',
    title: '创投孵化',
    coreLogo: 'https://p4.ssl.qhimg.com/t012f8bda47293d22f7.png',
    desc: '依托丰富的信息和资源背景，打造《创新沙盒独角兽大赛》、《未来数字安全名人堂》、《数字安全创新能力百强》等IP，深耕数字安全行业，发掘优质创新企业，拉通专业投融资机构，深度参与产业融合，共建良性、可持续发展的生态圈。目前已助推20余家企业完成数十亿元融资。',
    tag: ['孵化创新企业', '完成数十亿元融资']
  },
  {
    id: 5,
    key: 'ecology',
    headerImg: 'https://p0.ssl.qhimg.com/t01a8a5f1dcf43cc8a8.png',
    title: '生态联盟',
    coreLogo: 'https://p4.ssl.qhimg.com/t012f8bda47293d22f7.png',
    desc: 'ISC数字安全生态联盟发起“城市宏图生态计划”，通过为城市打造城市主题IP峰会、挖掘精尖特新企业、引入行业生态、培育孵化初创企业，推动亿级城市数字安全产业集群落地。目前ISC数字安全生态联盟已经发展了500多家生态厂商资源，200多家会员单位，通过安全能力的资源整合，抢抓城市产业集群发展机遇，携手共建城市数字安全产业生态，为数字中国建设增筋强骨',
    tag: ['500多家生态厂商资源', '共建城市数字安全产业生态']
  }
]
function changeSwipe(index) {
  currentNum.value = index
}

function toJump(path) {
  window.open(path)
}
</script>
<template>
  <div class="core">
    <van-swipe
      class="coreNav"
      :show-indicators="false"
      :width="325"
      :initial-swipe="currentNum"
      @change="changeSwipe"
    >
      <van-swipe-item class="coreNavList" v-for="item in coreArr" :key="item.key">
        <div class="listNav" :class="currentNum === item.id ? 'moveTop' : ''">
          <img :src="item.headerImg" alt="头图" class="coreNavListHeader" />
          <p class="coreNavListTitle">{{ item.title }}</p>
          <img class="coreNavListLogo" :src="item.coreLogo" alt="" />
          <p class="coreNavListDesc">{{ item.desc }}</p>
          <div class="coreNavTag">
            <span v-for="i in item.tag" :key="i" class="coreNavListTag">{{ i }}</span>
          </div>
          <div v-if="item.outSide" class="coreNavOut">
            <span
              v-for="i in item.outSide"
              :key="i"
              class="coreNavOutText"
              @click="toJump(i.router)"
            >
              <img :src="i.logo" alt="" />
              {{ i.text }}
            </span>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <ul class="coreMenu">
      <li
        v-for="item in coreArr"
        :key="item.key"
        :class="currentNum === item.id ? 'pitch' : ''"
        @click="changeSwipe(item.id)"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
// @keyframes up {
//   0% {
//     transform: translateY(0px);
//   }

//   100% {
//     transform: translateY(-24px);
//   }
// }
// .moveTop {
//   animation: up 0.5s linear;
//   animation-fill-mode: forwards;
// }
.core {
  background: url('https://p3.ssl.qhimg.com/t014f0fc5ce14c60e8a.png');
  padding: 36px 0;
  &Nav {
    &Out {
      text-align: left;
      &Text {
        display: inline-block;
        padding: 7px 10px;
        border: 1px solid #00ab7a;
        border-radius: 1px;
        font-weight: bold;
        font-size: 12px;
        color: #00ab7a;
        margin-right: 10px;
        margin-top: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .listNav {
      position: relative;
      width: 307px;
      min-height: 578px;
      margin: 72px 8px 37px;
      background: #ffffff;
      text-align: center;
      padding: 72px 20px 32px;
    }
    &List {
      min-height: 520px;
      right: -30px;
    }
    &ListHeader {
      position: absolute;
      top: -65px;
      left: 50%;
      transform: translateX(-50%);
    }
    &ListTitle {
      font-weight: bold;
      font-size: 20px;
      color: #222222;
      margin-bottom: 14px;
    }
    &ListDesc {
      font-size: 13px;
      color: #2f3b62;
      line-height: 24px;
      margin: 16px 0;
      text-align: justify;
    }
    &Tag {
      display: flex;
      flex-wrap: wrap;
    }
    &ListTag {
      background: rgba(170, 194, 252, 0.5);
      border-radius: 1px;
      padding: 4px 6px;
      font-size: 10px;
      color: #2c5adb;
      margin-right: 8px;
      margin-bottom: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &Menu {
    display: flex;
    margin: 0 11px;
    li {
      text-align: center;
      line-height: 28px;
      list-style: none;
      width: 68px;
      height: 28px;
      font-size: 13px;
      color: #222222;
    }
    .pitch {
      background: #ffffff;
      border-radius: 2px;
      color: #00ab7a;
    }
  }
}
</style>
