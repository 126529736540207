<script setup>

let currentYear = new Date().getFullYear();

</script>

<template>
  <div class="footer">
    <p class="footerCopy">Copyright© 2012-{{currentYear}} 360版权所有 &nbsp;&nbsp; ICP备20006251号-2</p>
    <p class="txt">
      <img src="https://p4.ssl.qhimg.com/t010f071ef8cf111525.png" alt="" />
      津公网安备 12011602001446号
    </p>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 56px !important;
  background: #222222 !important;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: inline-block !important;
    width: 20px;
    height: 20px;
    margin: 0 8px 0 34px;
  }
  .txt {
    display: flex;
    align-items: center;
  }
}
</style>
